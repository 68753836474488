<template>
  <div class="container">
    <div class="columns is-mobile is-vcentered">
      <div class="column is-narrow">
        <p class="is-size-3">
          <span class="icon is-medium has-text-primary is-valigned">
            <i class="fad fa-car" />
          </span>
          <span class="title has-text-info is-valigned">
            {{ make.toUpperCase() }}
          </span>
        </p>
      </div>
      <div class="column">
        <div class="tags is-multiline">
          <span
            class="tag has-text-weight-bold is-soft"
            v-for="(model, index) in models"
            :key="index"
          >
            {{ model }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ExplorerMakeModelBanner',
  computed: {
    ...mapGetters('explorer', ['make', 'models'])
  }
}
</script>
